import Map from "@identitybuilding/idb-react-100-percent-local"
import MapStadGemeente from "@identitybuilding/idb-react-100-percent-local/dist/MapStadGemeente"


import { useHistory } from "react-router-dom";
import React from "react";
import Navigation from "./Navigation";
import { useSelector, useDispatch } from "react-redux";
import { toggleLoginScreen } from "../actions/AccountAction";
import axios from "axios";
import { useEffect, useState } from "react";

const Main = (props) => {
  const history = useHistory();
  const dispatch = useDispatch(); // Initialize dispatch
  const lang = useSelector((state) => state.general.lang);
  const loginScreen = useSelector((state) => state.account.loginScreen); // Access loginScreen state
  const accountData = useSelector((state) => state.account.accountData); // Determine if logged in
  const [followingMunicipalities, setFollowingMunicipalities] = useState([]); // State for followed municipalities
  const { followState } = useSelector((state) => state.account);
  const [loading, setLoading] = useState(true); // Add loading state
  useEffect(() => {
    const navigationElement = document.querySelector("nav"); // Adjust the selector if necessary
    if (loginScreen) {
      navigationElement?.classList.add("blurred");
    } else {
      navigationElement?.classList.remove("blurred");
    }
  }, [loginScreen]);

  const login = (e) => {
    e.preventDefault();
    dispatch(toggleLoginScreen(true)); // Example Redux action
  };
  useEffect(() => {
    // Fetch the user's followed municipalities from the backend
    const fetchFollowingMunicipalities = async () => {
      if (localStorage.getItem("StadGemeenteToken")) {
        try {
          const headers = {
            "Content-Type": "application/json",
            Authorization: `Token ${localStorage.getItem("StadGemeenteToken")}`,
          };
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_MEDIA_URL}/core/api/stadgemeente/user/`,
            { headers }
          );
          if (response.data?.following_municipalities_nis_codes) {
            const nisCodes = response.data.following_municipalities_nis_codes;
            setFollowingMunicipalities(
              nisCodes // Use the correct key
            );
          }
        } catch (error) {
          console.error("Error fetching following municipalities:", error);
        }
      }
      setLoading(false); // Set loading to false after data is fetched
    };

    fetchFollowingMunicipalities();
  }, []); // Run only once on component mount

  const goToPage = (e) => {

    let municipalityName = e["Name" + lang.toUpperCase()] ? e["Name" + lang.toUpperCase()].toLowerCase()
      .replace(" (stad)", '')
      .replace(" (ville)", '')
      .replace(" (stadt)", '')
      .replace(" city", '')
      .replace(/\ /g, '')
      .replace("'", '')
      .replace("lalouviere", "la louvière")
      .replace("lacalamine", "la calamine")
      .replace("labruyere", "la-bruyere")
      .replace("lahulpe", "la hulpe")
      .replace("fontaine-lévêque", 'fontaine-levêque')
      :
      e.name
        .replace(" (stad)", '')
        .replace(" (ville)", '')
        .replace(" (stadt)", '')
        .replace(" city", '')
        .replace(/\ /g, '')
        .replace("'", '')
        .replace("lalouviere", "la louvière")
        .replace("lacalamine", "la calamine")
        .replace("labruyere", "la-bruyere")
        .replace("lahulpe", "la hulpe")
        .replace("fontaine-lévêque", 'fontaine-levêque')
    if (municipalityName === 'sgravenbrakel') { municipalityName = 's-gravenbrakel' }
    if (municipalityName === 'mont-de-lenclus') { municipalityName = 'montdelenclus' }
    if (municipalityName === 'lesbonsvillers') { municipalityName = 'les-bons-villers' }
    if (window.location.hostname === 'localhost') {
      history.push(`/${municipalityName.toLowerCase()}`);
    }
    else {
      window.open(`https://${window.location.hostname}/${municipalityName.toLowerCase()}`)
    }


  }

  return (
    <div>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          
        </div>
      ) : (
        <>
          <Navigation lang={lang} />
          {accountData && window.location.href.includes("samenwerking_gemeenten") ? (
            <Map
              lang={lang}
              MyCity={true}
              getData={(e) => goToPage(e)}
              loggedIn={Boolean(accountData)}
              followingMunicipalities={followingMunicipalities}
              followState={followState}
              login={login}
            />
          ) : accountData ? (
            <MapStadGemeente
              lang={lang}
              MyCity={true}
              getData={(e) => goToPage(e)}
              loggedIn={Boolean(accountData)}
              followingMunicipalities={followingMunicipalities}
              followState={followState}
              login={login}
            />
          ) : (
            <Map
              lang={lang}
              MyCity={true}
              getData={(e) => goToPage(e)}
              loggedIn={Boolean(accountData)}
              followingMunicipalities={followingMunicipalities}
              followState={followState}
              login={login}
            />
          )}

        </>
      )}
    </div>
  );
};

export default Main;
