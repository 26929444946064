const initialState = {
    loginScreen: false,
    accountData: "",
    openFollowingConflict: false,
    toFollowEstablishment: "",
    followState: JSON.parse(localStorage.getItem("followState")) ?? false, 
    isLoggedIn: JSON.parse(localStorage.getItem("isLoggedIn")) ?? false, 
}

const AccountReducer = (state = initialState, action) => {

    switch (action.type) {
        case "TOGGLE_LOGIN_SCREEN": {
            return {
                ...state,
                loginScreen: action.value
            }
        }
        case "UPDATE_ACCOUNT_INFO": {
            return {
                ...state,
                accountData: action.value
            }
        }
        case "REMOVE_FROM_SAVED_CMPS": {
            let copy = state.accountData
            copy.saved_cmps.splice(action.index, 1)
            return {
                ...state,
                accountData: copy
            }
        }
        case "REMOVE_FOLLOWING_ESTABLISHMENT": {
            return {
                ...state,
                accountData: action.value
            }
        }
        case "TOGGLE_OPEN_FOLLOWING_CONFLICT": {
            return {
                ...state,
                openFollowingConflict: action.value
            }
        }
        case "TO_FOLLOW_EST": {
            return {
                ...state,
                toFollowEstablishment: action.value
            }
        }
        case "TOGGLE_FOLLOW_STATE": {
            const followState = action.value !== undefined ? action.value : !state.followState;
            localStorage.setItem("followState", JSON.stringify(followState)); // Save to localStorage
            return {
                ...state,
                followState, // Update state with the new value
            };
        }
        case "TOGGLE_LOGGED_IN_STATE": {
            const isLoggedIn = action.value !== undefined ? action.value : !state.isLoggedIn;
            localStorage.setItem("isLoggedIn", JSON.stringify(isLoggedIn)); // Save to localStorage
            return {
                ...state,
                isLoggedIn, // Update state
            };
        }
        
        
        
        default:
            return state;
    }
}

export default AccountReducer;