
import { useSelector, useDispatch } from "react-redux";
import Icon from "@identitybuilding/idb-react-iconlib";
import React, { useEffect, useState } from "react";
import "../assets/css/FollowingConflict.css"
import "../assets/css/ShareButtons.css"
import { Button, OsnInputText } from "@identitybuilding/idb-react-ui-elements";
import { toggleOpenFollowingConflict } from '../actions/index'
import axios from 'axios'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { EmailIcon, FacebookIcon, FacebookMessengerIcon, FacebookMessengerShareButton, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TwitterShareButton, WhatsappIcon, WhatsappShareButton, XIcon, } from "react-share";

const FollowingConflict = (props) => {
    const { translate, lang } = useSelector(state => state.general);
    const shareUrl = `https://www.catalogusnetwerk.be${window.location.pathname}`
    const [mail, setMail] = useState(false);
    const [fromMail, setFromMail] = useState("");
    const [error, setError] = useState("");
    const [toMails, setToMails] = useState([]);
    const [toMailText, setToMailText] = useState("");
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        swipeToSlide: true,
    };
    const copy_to_clipboard = (value) => {
        let copyText = document.location.href;
        let textArea = document.createElement('textarea');
        textArea.value = copyText;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('Copy');
        textArea.remove();

        props.createNotification("info", translate('copied_to_clipboard'))

    }

    // when pressing escape, this will close the login screen
    const keyDownHandler = (e) => {
        if (e.keyCode === 27) {
            props.setShare(false)
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', keyDownHandler);
    })


    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    const addToMail = (e) => {
        let copy = [...toMails]
        if (isValidEmail(e)) {
            if (copy.includes(e)) {
            }
            else {
                copy.push(e)
            }
        } else {
            props.createNotification("warning", translate('not_a_valid_email'))

        }
        setToMails(copy)
        setToMailText("")
    }

    const removeToMail = (index) => {
        let copy = [...toMails]
        copy.splice(index, 1)
        setToMails(copy)
    }

    const toMailHandler = (e) => {
        if (e.keyCode === 13) {
            addToMail(e.target.value)
        }
    }

    const sendMail = () => {
        if (isValidEmail(fromMail)) {
            if (toMails.length > 0) {
                setIsLoading(true); 
                axios.post(`${process.env.REACT_APP_BASE_MEDIA_URL}/core/api/stadgemeente/send_share_mail/`, {
                    from_mail: fromMail,
                    to_mails: toMails,
                    first_name: firstName,
                    last_name: lastName,
                    site: "stadgemeente",
                    lang: lang
                }).then((res) => {
                    setIsLoading(false);
                    props.setShare(false);
                    props.createNotification("info", translate('mail_sent'))
                })
            }
            else if (toMailText && isValidEmail(toMailText)) {
                props.createNotification("warning", translate('please_select_add_button'));
            }
            else {
                setError("toMails")
            }
        }
        else {
            props.createNotification("warning", translate('not_a_valid_email'))
            setError("fromMail")
        }
    }


    let preview_nl = ` <html>
                        <body>
                            <table align="center" border="0" cellpadding="0" cellspacing="0">
                                <tr>
                                    <td>
                                        <p>
                                            <b>${firstName} ${lastName}</b> heeft <b><a href="https://mijn.stad-gemeente.be" style="color: #1693D4 !important; text-decoration: underline;">mijn.stad-gemeente.be</a></b> 
                                            ontdekt met alle voordelen en wil dit met jou delen.
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Meer info hieronder:</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <img src="https://zappa-tlaqv351d.s3.amazonaws.com/media/images/nl.gif" alt="Mijn Stad-Gemeente"  style="width: 100%; max-width: 500px; height: auto; border: 0;"/> 
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>
                                            Bezoek <b><a style="color: #1693D4 !important; text-decoration: underline;" href="https://mijn.stad-gemeente.be">https://mijn.stad-gemeente.be</a> </b>
                                            en maak een keuze voor de steden en gemeenten die jij wil volgen!
                                        </p>
                                    </td>
                                </tr>
                            </table>
                        </body>
                    </html>`;


    let preview_fr = ` <html>
                    <body>
                        <table align="center" border="0" cellpadding="0" cellspacing="0">
                            <tr>
                                <td>
                                    <p>
                                        <b>${firstName} ${lastName}</b> a découvert <a href="https://ma.ville-commune.be">ma.ville-commune.be</a> 
                                        avec tous les avantages et souhaite les partager avec toi.
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>Plus d'infos ci-dessous :</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img src="https://zappa-tlaqv351d.s3.amazonaws.com/media/images/fr.gif" alt="Ma Ville-Commune" style="width: 100%; max-width: 500px; height: auto; border: 0;" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>
                                        Visitez <a href="https://ma.ville-commune.be">https://ma.ville-commune.be</a> 
                                        et choisissez les villes et communes que vous souhaitez suivre !
                                    </p>
                                </td>
                            </tr>
                        </table>
                    </body>
                </html>`;



    return (
        <div className="shareButtons">
            {mail ?
                <div className="container">
                    <Icon name="ArrowLongLeft" className="close back" onClick={() => setMail(false)} />
                    <h3>{translate('share_your_favorite_entrepreneur_with_friends_and_or_family')}</h3>
                    <div className="user_email">
                        <OsnInputText
                            className="form_field"
                            error={error === 'firstName'}
                            title={lang === 'nl' ? "Voornaam" : "Prénom"}
                            value={firstName}
                            onChange={(e) => {
                                setFirstName(e.target.value);
                                setError('');
                            }}
                        />
                        <OsnInputText
                            className="form_field"
                            error={error === 'lastName'}
                            title={lang === 'nl' ? "Achternaam" : "Nom de famille"}
                            value={lastName}
                            onChange={(e) => {
                                setLastName(e.target.value);
                                setError('');
                            }}
                        />
                    </div>
                    <OsnInputText className="user_email" error={error === 'fromMail' ? true : false} title={lang === 'nl' ? "Jouw email" : "Votre email"} value={fromMail} onChange={(e) => { setFromMail(e.target.value); setError('') }} />

                    <div className="to-mails-input">

                        <OsnInputText
                            className="form_field"
                            error={error === 'toMails'}
                            title={lang === 'nl' ? "Email ontvanger(s)" : "Email récepteur(s)"}
                            value={toMailText}
                            onKeyDown={(e) => e.keyCode === 13 && addToMail(toMailText)}
                            onChange={(e) => {
                                setToMailText(e.target.value);
                                setError('');
                            }}
                        />
                        <button
                            type="button"
                            className="add-email-button"
                            onClick={() => {
                                if (isValidEmail(toMailText)) {
                                    addToMail(toMailText);
                                } else {
                                    props.createNotification("warning", translate('not_a_valid_email'));
                                }
                            }}
                        >
                            +
                        </button>
                    </div>


                    <p className="infoText"> {lang === 'nl' ? 'Vul een email in en druk op plus!' : 'Entrez un e-mail et appuyez sur plus!'} <br /> {lang === 'nl' ? 'Je kan ook meerdere email adressen toevoegen.' : 'Vous pouvez également ajouter plusieurs adresses e-mail.'} </p>
                    <div className="labels">
                        {toMails.map((mail, index) => (
                            <span className="label"> <Icon onClick={() => removeToMail(index)} name="Close" /> {mail}</span>
                        ))}
                    </div>
                    {/* <Button
                        className="override-red-box-shadow"
                        text={`${translate('send')}`}
                        txtColor='pn'
                        type='sub'
                        size='S'
                        onClick={() => sendMail()}
                    /> */}
                    <div className="SendMailButtonWrapper">
                        {isLoading ? (
                            <div className="loading-spinner">
                                {/* Replace this with an actual spinner component or style */}
                                <div className="spinner blue-circle"></div>
                            </div>
                        ) : (
                            <div
                                size='S'
                                className="sendmailbutton"
                                text={`${translate('send')}`}
                                type='sub'
                                onClick={() => sendMail()}
                            >
                                {translate('send')}
                            </div>
                        )}
                    </div>
                    {/* <h3>{lang === 'nl' ? 'Bericht:' : 'Message :'}</h3> */}
                    <h3>{lang === 'nl' ? '' : ''}</h3>
                    <p className="message" dangerouslySetInnerHTML={{ __html: lang === 'nl' ? preview_nl : preview_fr }} />





                </div>
                :
                <div className="container">
                    <Icon name="Close" className="close" onClick={() => props.setShare(false)} />
                    {lang === 'nl' ?
                        <h3>Deel jouw favoriete <b>STAD OF GEMEENTE</b> met familie en vrienden</h3>
                        :
                        <h3>Partagez votre <b>VILLE OU COMMUNE</b> préférée avec votre famille et vos amis</h3>}

                    {lang === 'nl' ?
                        <p>Kies hieronder een platform naar keuze om te delen!</p>
                        :
                        <p>Choisissez une plateforme de votre choix ci-dessous pour partager !</p>
                    }
                    <figure>
                        <img src={require('../assets/imgs/PLENNY FINAL_Tekengebied 1.svg').default} />
                    </figure>

                    <div className="buttons">
                        <FacebookShareButton
                            url={shareUrl}
                            className="shareButton facebook"
                        >
                            <FacebookIcon size={40} round />
                        </FacebookShareButton>

                        {/* ---------------------------------------------- */}

                        <FacebookMessengerShareButton
                            url={shareUrl}
                            appId="521270401588372"
                            className="shareButton messenger"
                        >
                            <FacebookMessengerIcon size={40} round />
                        </FacebookMessengerShareButton>

                        {/* ---------------------------------------------- */}

                        <TwitterShareButton
                            url={shareUrl}
                            title={'Ontdek alle voordelen van het stadgemeente'}
                            className="shareButton twitter"
                        >
                            <XIcon size={40} round />
                        </TwitterShareButton>
                        {/* ---------------------------------------------- */}
                        <WhatsappShareButton
                            url={shareUrl}
                            title={'Ontdek alle voordelen van het stadgemeente'}
                            separator=":: "
                            className="shareButton whatsapp"
                        >
                            <WhatsappIcon size={40} round />
                        </WhatsappShareButton>
                        {/* ---------------------------------------------- */}
                        <LinkedinShareButton
                            url={shareUrl}
                            className="shareButton linkedin"
                        >
                            <LinkedinIcon size={40} round />
                        </LinkedinShareButton>
                        {/* ---------------------------------------------- */}
                        {/* <EmailShareButton
                            url={shareUrl}
                            subject={'Ontdek alle voordelen van het Catalogusnetwerk'}
                            body=""
                            
                        > */}
                        <EmailIcon size={40} round className="shareButton email" onClick={() => setMail(true)} />
                        {/* </EmailShareButton> */}
                        {/* ---------------------------------------------- */}
                        <Icon name="Share" className="shareButton clipboard" onClick={() => copy_to_clipboard()} />


                    </div>
                    <div className="slider">
                        <div className="slider-container-share">
                            {lang === 'nl' ? (
                                <Slider {...sliderSettings}>
                                    <div><img src='/SliderImages/Plenny__mijn.stad-gemeente-1.svg' alt="i1" /></div>
                                    <div><img src='/SliderImages/Plenny__mijn.stad-gemeente-2.svg' alt="i2" /></div>
                                    <div><img src='/SliderImages/Plenny__mijn.stad-gemeente-3.svg' alt="i3" /></div>
                                    <div><img src='/SliderImages/Plenny__mijn.stad-gemeente-4.svg' alt="i4" /></div>
                                    <div><img src='/SliderImages/Plenny__mijn.stad-gemeente-5.svg' alt="i5" /></div>
                                </Slider>
                            ) : (
                                <Slider {...sliderSettings}>
                                    <div><img src='/SliderImages/Plenny_ma.ville-commune-1.svg' alt="i1" /></div>
                                    <div><img src='/SliderImages/Plenny_ma.ville-commune-2.svg' alt="i2" /></div>
                                    <div><img src='/SliderImages/Plenny_ma.ville-commune-3.svg' alt="i3" /></div>
                                    <div><img src='/SliderImages/Plenny_ma.ville-commune-4.svg' alt="i4" /></div>
                                    <div><img src='/SliderImages/Plenny_ma.ville-commune-5.svg' alt="i5" /></div>
                                </Slider>
                            )}

                        </div>
                    </div>
                </div>

            }
        </div>
    );
}

export default FollowingConflict;
