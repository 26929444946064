import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../assets/css/Login.css"
import { toggleLoginScreen } from "../actions/AccountAction"
import Icon from "@identitybuilding/idb-react-iconlib";
import { OsnInputText } from "@identitybuilding/idb-react-ui-elements";
import axios from 'axios';
import { useHistory, useParams } from "react-router-dom";

// function useForceUpdate() {
//     const [value, setValue] = useState(0); // integer state
//     return () => setValue(value => value + 1); // update state to force render
//     // An function that increment 👆🏻 the previous state like here 
//     // is better than directly setting `value + 1`
// }


const Login = (props) => {
    // const forceUpdate = useForceUpdate();
    const { token } = useParams();

    const dispatch = useDispatch()
    const history = useHistory();
    const { translate, lang } = useSelector(state => state.general);
    const [error, setError] = useState('');
    
    const [email, setEmail] = useState('');
    const [forgotPassword, setForgotPassword] = useState('');
    const [forgotRepeatPassword, setForgotRepeatPassword] = useState('');

    const [viewPassword, setViewPassword] = useState(false);
    const [viewPassword2, setViewPassword2] = useState(false);


    const isUpper = (str) => {
        return /[A-Z]/.test(str);
    }
    const hasNumber = (str) => {
        return /[0-9]/.test(str);
    }

    const passwordResetHandler = () => {
        if (email === '') { props.createNotification("warning", translate('please_enter_an_email')); setError('email') }
        else if (!/\S+@\S+\.\S+/.test(email)) { props.createNotification("warning", translate('not_a_valid_email')); setError('email') }
        else if (forgotPassword === "") { props.createNotification("warning", translate('please_enter_a_password')); setError('password') }
        else if (forgotPassword.length < 8) { props.createNotification("warning", translate('password_8_characters')); setError('password') }
        else if (!isUpper(forgotPassword)) { props.createNotification("warning", translate('password_1_capital')); setError('password') }
        else if (!hasNumber(forgotPassword)) { props.createNotification("warning", translate('password_1_number')); setError('password') }
        else if (forgotRepeatPassword === "") { props.createNotification("warning", translate('enter_password_repeat')); setError('repeat_password') }
        else if (forgotPassword !== forgotRepeatPassword) { props.createNotification("warning", translate('passwords_do_not_match')); setError('repeat_password') }
        else {
            axios.post(`${process.env.REACT_APP_BASE_MEDIA_URL}/core/api/stadgemeente/reset/`, {
                email: email,
                password: forgotPassword,
                repeat_password: forgotRepeatPassword,
                token: token,
            }).then((res) => {
                props.createNotification("info", translate('password_successfully_changed'));
                history.push(`/`)

            })
        }
    }

    return (
        <div id="login_wrapper">
            <div className="login_content">
                <Icon className="close_icon" name="Close" onClick={() => dispatch(toggleLoginScreen(false))} />
                <figure>
                    <img src={require("../assets/imgs/logos/pn/promonetwerk-01.svg").default} alt="logo promonetwork" />
                </figure>
                    {lang === 'nl' ? 
                        <h2>Welkom op het <span style={{ "color": "var(--pn)" }}>&nbsp;Promonetwerk</span></h2>
                    :
                        <h2>Bienvenue sur le Réseau<span style={{ "color": "var(--pn)" }}>promo</span></h2>
                    }

                        <div className="innerLogin">
                                <div className="login">
                                    <OsnInputText error={error === 'email' ? true : false} className="email" icon="Mail" title={translate('email')} onChange={(e) => { setEmail(e.target.value); setError('') }} />
                                    <OsnInputText error={error === 'password' ? true : false} className="password" cta_icon={viewPassword ? 'Hidden' : "Visible"} cta_click={() => setViewPassword(!viewPassword)} icon="Locked" title={translate('password')} type={viewPassword ? "text" : "password"} onChange={(e) => { setForgotPassword(e.target.value); setError('') }} />
                                    <OsnInputText error={error === 'repeat_password' ? true : false} className="password" cta_icon={viewPassword2 ? 'Hidden' : "Visible"} cta_click={() => setViewPassword2(!viewPassword2)} icon="Locked" title={translate('password_repeat')} type={viewPassword2 ? "text" : "password"} onChange={(e) => { setForgotRepeatPassword(e.target.value); setError('') }} />
                                    
                                    <div className="login_button" onClick={() => passwordResetHandler()}>{translate('reset_password')}</div>
                                </div>
                        </div>
            </div>
            <div className="login_bg" onClick={() => dispatch(toggleLoginScreen(false))}></div>
        </div>
    );
}

export default Login;
